<template>
  <div :class="windowClass">
    <v-container>
      <v-row no-gutters>
                <div style="width:100%" id="scanme">
          <v-zxing
            @decode="onDecode"
            @loaded="onLoaded"
          />
        </div>
      </v-row>
     </v-container>
     <va-data-preview  :person= "person" :members="members" :show="preview" @DialogEvent="evdp"/>
   </div>
 </template>
 <script>

 import {
   mapMutations
 } from 'vuex'
   export default {
     data: () => ({
         form: false,
         search: '',
         scannedData: '',
         headID: null,
         person: {},
         preview: false, 
         members: {}, 
         items:[],
     
     }),
     beforeCreate: function () {
       if (!this.$session.exists()) {
         this.$router.push('/')
       }
     },
     created() {
        if (this.$session.exists()) {
           this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
           this.setLoggedIn(true) 
           
          
         }
     },
     computed:{
        windowClass() {
         if(this.$store.state.responsive){
           return "va-reponsive"
         } else {
           return "va-not-reponsive"
         }
       },
       
     },
     methods: {
       ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
       evdp(){
          this.preview = false
          this.headID = null
       },
       onLoaded() {
        console.log("loaded")
       },
       onDecode (result) {
        if(!this.headID) {
      
          this.headID = this.$IsNum(result.trim())
          this.ViewData(this.headID )
        }
      },
      ViewData(id) {
         
        this.$http.post("data/view", {id:id}).then(response => {
          this.preview = true
            response.data.status?this.person = response.data.item:this.person={}
            response.data.members != null?this.members = response.data.members:this.members=[]
            
            this.headID = null
             console.log(response.data)
            // this.view = true
        }).catch(e => {
        console.log(e)
        })
        },
   }
 } 
 </script>